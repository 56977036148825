<template>
    <div class="page container">
        <header class="intro">
            <div
                style="width: 3rem; margin: 0 auto 0.5rem;"
                class="vendor-icon"
                v-html="require('@web/assets/linchpin.svg')"
            ></div>
            <h2 class="centered">
                {{ $t('welcome_to_linchpin_title') }}
            </h2>
        </header>
        <div class="divider" ></div>

        <section class="configuration">
            <div class="grid">
                <div class="col">
                    <div class="description">
                        <p>
                            {{ $t('welcome_to_linchpin_title_instructions_1') }}
                            <a
                                :href="hostUrl"
                                target="_blank"
                            >{{ host }}</a>
                            {{ $t('welcome_to_linchpin_title_instructions_2') }}
                        </p>
                        <p>
                            {{ $t('welcome_to_linchpin_title_instructions_3') }}
                        </p>
                    </div>

                    <div class="link-list">
                        <a
                            :href="hostUrl"
                            target="_blank"
                        >
                            <app-button
                                type="link"
                                icon="external-link"
                            >{{ $t('integration_setup') }}</app-button>
                        </a>
                        <br/>
                        <a
                            href="https://info.seibert-media.net/display/DOC/Quickstart+-+Linchpin+Cloud"
                            target="_blank"
                        >
                            <app-button
                                type="link"
                                icon="bookmark"
                            >{{ $t('documentation') }}</app-button>
                        </a>
                        <br/>
                        <a
                            href="https://cloud.linchpin-intranet.com/"
                            target="_blank"
                        >
                            <app-button
                                type="link"
                                icon="contact-im"
                            >{{ $t("need_help") }} {{ $t("contact_us") }}
                            </app-button>
                        </a>
                    </div>
                </div>
                <div
                    v-if="isUserLoggedIn"
                    class="col"
                >
                    <div class="card padding-medium">
                        <h3>Options</h3>
                        <LoadingWrapper :is-loading="isFetching">
                            <div class="form-group system-select">
                                <label for="system-select">{{ $t('choose_system') }}</label>
                                <vSelect
                                    label="intranet"
                                    :searchable="false"
                                    :placeholder="$t('choose_system')"
                                    :options="availableIntranets"
                                    :disabled="isSelectIntranetLoading"
                                    :value="connectedIntranet"
                                    @input="selectIntranet"
                                >
                                    <template #option="option">
                                        <div class="system-select-row">
                                            <div
                                                class="system-logo"
                                                :style="`background-image:url(${option.intranet.logoUrl})`"
                                            ></div>

                                            <div class="text">
                                                <span>{{ option.intranet.displayName }}</span>
                                                <span class="meta">{{ option.intranet.userCount }} Users</span>
                                            </div>
                                        </div>
                                    </template>

                                    <template #selected-option="option">
                                        <div class="system-select-row selected">
                                            <div
                                                class="system-logo"
                                                :style="`background-image:url(${option.intranet.logoUrl})`"
                                            ></div>

                                            <div class="text">
                                                <span>{{ option.intranet.displayName }}</span>
                                            </div>
                                        </div>
                                    </template>
                                </vSelect>
                                <div
                                    v-if="isUnknownIntranet || (!isUnknownIntranet && connectedIntranet)"
                                    class="connection-status"
                                >
                                    <div
                                        class="bubble"
                                        :class="{nope: isUnknownIntranet, yep: !isUnknownIntranet && connectedIntranet}"
                                    ></div>
                                    <span v-if="isUnknownIntranet">{{ $t('choose_system_connected_unknown') }}</span>
                                    <span v-if="!isUnknownIntranet && connectedIntranet">{{ $t('choose_system_connected') }}</span>
                                </div>
                            </div>

                            <div class="divider" ></div>

                            <div class="form-group">
                                <input
                                    id="show_linchpin_in_system_header"
                                    v-model="showInSystemHeader"
                                    type="checkbox"
                                    name="show_linchpin_in_system_header"
                                    :disabled="isShowInSystemHeaderLoading"
                                    @change="changeShowInSystemHeader"
                                />
                                <label for="show_linchpin_in_system_header">{{ $t('show_linchpin_in_system_header') }}</label><br/>
                            </div>
                        </LoadingWrapper>
                    </div>
                </div>
            </div>
        </section>
        <div ></div>
    </div>
</template>
<script>
import Vue from "vue";
import vSelect from "vue-select";
import {
    confluenceConfigurationService,
    intranetService,
    resizingService,
} from "@web/services";
import { IntranetRole } from "@backend/intranet/types";
import ConfluenceMacroMixin from "@web/views/atlassian-cloud/confluence/macro/ConfluenceMacroMixin.vue";

/**
 * this is the configuration page for the confluence cloud app
 */
export default Vue.extend({
    name: "ConfluenceCloudConfiguration",
    components: {
        vSelect
    },
    mixins: [
        ConfluenceMacroMixin
    ],
    data() {
        return {
            showInSystemHeader: false,
            isShowInSystemHeaderLoading: true,
            isSelectIntranetLoading: true,
            connectedIntranet: "",
            isUnknownIntranet: false,
            isFetching: true,
            availableIntranets: []
        };
    },
    computed: {
        host() {
            return (new URL(this.hostUrl)).host;
        }
    },
    async mounted() {
        this.appendAtlassianScriptToHead(() => {
            resizingService.resize({ sizeToParent: true });
        });
        this.initShowInSystemHeader();
        this.initSelectIntranet();
    },
    methods: {
        async initShowInSystemHeader() {
            this.showInSystemHeader = await confluenceConfigurationService.getShowInSystemHeader()
                .finally(() => this.isShowInSystemHeaderLoading = false);
        },
        async initSelectIntranet() {
            this.isUserLoggedIn = this.isUserLoggedInIntoFirebase();
            if (this.isUserLoggedIn !== true) {
                return;
            }

            this.availableIntranets = (await intranetService.getAvailableIntranetsForCurrentUser()).filter(i => i.roles.includes(IntranetRole.admin));
            const connectedIntranetUid = await confluenceConfigurationService.getIntranet();
            if (connectedIntranetUid) {
                this.connectedIntranet = this.availableIntranets.find(i => i.intranet.uid === connectedIntranetUid);
                if (this.connectedIntranet === undefined) {
                    this.isUnknownIntranet = true;
                }
            }
            this.isSelectIntranetLoading = false;
            this.isFetching = false;
        },
        async changeShowInSystemHeader() {
            this.isShowInSystemHeaderLoading = true;
            await confluenceConfigurationService.setShowInSystemHeader(this.showInSystemHeader);
            this.isShowInSystemHeaderLoading = false;
        },
        async selectIntranet(option) {
            this.isSelectIntranetLoading = true;
            if (option === null) {
                await confluenceConfigurationService.removeIntranet();
            } else {
                await confluenceConfigurationService.setIntranet(option.intranet.uid);
            }
            this.connectedIntranet = option;
            this.isSelectIntranetLoading = false;
            this.isUnknownIntranet = false;
        }
    }
});
</script>

<style lang="scss" scoped>
    .page {
        max-width: 960px;
        padding: 3rem;
    }

    .intro {
        margin-bottom: 3rem;

        & + .divider {
            margin-bottom: 3rem;
        }

    }

    .description {
        font-size: 0.9rem;
        color: var(--highest-contrast, #{$dark-grey});
    }

    .link-list {
        &::v-deep .button {
            padding-left: 0.25rem;
            margin-bottom: 0.25rem;
        }
    }

    .system-select-row {
        display: flex;
        align-items: center;
        padding: 0.75rem 0;

        .system-logo {
            flex: 0 0 auto;
            width: 2rem;
            height: 2rem;
            background: var(--lowest-contrast, #{$off-white}) center center no-repeat;
            background-size: contain;
            border-radius: 3px;
            margin-right: 0.5rem;
        }

        .text {
            flex: 1 1 auto;
            width: 200px;

            span {
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

            }
        }

        &.selected {
            padding: 0;

            .system-logo {
                width: 1.5rem;
                height: 1.5rem;
            }

        }

    }

    .connection-status {
        text-align: left;
        font-size: 0.75rem;
        color: var(--medium-contrast, #{$mid-grey});
        margin: 0.5rem 0;
        display: flex;
        span {
            line-height: 1.5;
        }
    }

    .bubble {
        display: block;
        flex: 0 0 auto;
        width: 8px;
        height: 8px;
        margin-right: 6px;
        margin-top: 4px;
        border-radius: 50%;
        background: var(--lowest-contrast, #{$off-white});

        &.nope {
            background: #ff3300;
        }

        &.yep {
            background: #afce00;
        }
    }
</style>
